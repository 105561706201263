import { useDispatch, useSelector } from 'react-redux';
import { selectScenarios, selectUserState } from '../../redux/user/userGetters';
import * as checks from './stageChecks';
import { ID, to } from './ID';
import { ScenarioHelperResponse } from './types';
import { useRunScenarioAsync } from '../useRunScenarioAsync';
import { useRunScenarioSequence } from './utils';
import { useIsFirstStageCompleted } from '../stageProgressChecks/useIsFirstStageCompleted';
import { stage1 } from './stage1';
import { stage2 } from './stage2';
import { useIsSecondStageCompleted } from '../stageProgressChecks/useIsSecondStageCompleted';
import { stage3 } from './stage3';
import { hasBlanks } from '../stageProgressChecks/requiredScenarios';
import { SceneId } from '../../types/scenario';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// ScenarioHelperResponse

export function useScenarioHelper(): any {
  const scenarioState = useSelector(selectScenarios);
  const userState = useSelector(selectUserState);
  const location = useLocation();

  const dispatch = useDispatch();

  const runScenario = useRunScenarioAsync();
  const runModalScenario = useRunScenarioSequence();

  const isFirstStageCompleted = useIsFirstStageCompleted();
  const isSecondStageCompleted = useIsSecondStageCompleted();

  const [stop, setStop] = useState<any>(null);

  useEffect(() => {
    if (
      !scenarioState.scenarios?.[to[ID.stage2_act1_mother]] &&
      scenarioState.scenarios?.[to[ID.stage2_act1_arch]]?.at(-1)?.type === 'exit' &&
      !scenarioState.activeChatScenario
    ) {
      setStop({
        pointsHandlers: [],
        autorunScenario: {
          id: to[ID.stop_1],
          unsaved: false,
        },
      });
    }
  }, [scenarioState]);

  useEffect(() => {
    if (
      !scenarioState.scenarios?.[to[ID.stage2_act1_mother]] &&
      scenarioState.scenarios?.[to[ID.stage2_act1_arch]]?.at(-1)?.type === 'exit' &&
      !scenarioState.activeChatScenario
    ) {
      // @ts-ignore
      if (location.state?.from === 'profile' && !sessionStorage.getItem('reloaded')) {
        sessionStorage.setItem('reloaded', 'true');
        window.location.reload();
      }
    }
  }, [scenarioState]);

  // Начало игры
  if (checks.notStarted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.start],
        runOnSubmit: to[ID.catWelcome],
      },
    };
  }

  // const blanks = hasBlanks(scenarioState.scenarios);
  // if (blanks && !scenarioState.scenarios[SceneId.scenario_blanks]) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: SceneId.scenario_blanks },
  //   };
  // }

  if (stop) {
    return stop;
  }

  if (!isFirstStageCompleted) {
    return stage1(scenarioState, runScenario, runModalScenario);
  }

  if (!isSecondStageCompleted) {
    return stage2(scenarioState, runScenario, runModalScenario, dispatch);
  }

  // return stage3(scenarioState, runScenario, runModalScenario, dispatch, userState);
}
