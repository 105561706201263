import React from 'react';
import { Text } from '../Text';
import { HeaderModalVideoText } from './ModalAttachmentVideoStyled';
import colors from '../../constants/colors';

export const IntroductionText = () => {
  return (
    <HeaderModalVideoText $textStyle='p18'>
      <Text $textStyle='p18' $fontWeight='800' $color={colors.title} $mB='10px'>
        Видеовизитка
      </Text>
      {/* Прием видеовизиток завершен */}
      Запиши видеовизитку продолжительностью от 1 до 2 минут. В ней расскажи самое важное и
      интересное о себе. Это могут быть твои достижения, хобби или проект, который ты планируешь
      реализовать или, возможно, уже реализовал. Расскажи о том, что для тебя важно, что ты ценишь и
      чем по-настоящему гордишься.
      <br />
      <br />
      Тема шестого сезона— «От мечты к свершению», поэтому мы хотим узнать подробнее о твоей мечте,
      ведь какое детство без мечты?
      <br />
      <br /> Расскажи нам, о чем ты мечтаешь и что ты можешь сделать, чтобы твоя мечта стала
      реальностью. Или, возможно, твоя мечта уже осуществилась, и тогда ты можешь рассказать, как
      это произошло и есть ли у тебя теперь новая мечта, к которой ты стремишься. <br />
      <br />В видеовизитке нам важно увидеть тебя и услышать твой голос, поэтому очень надеемся, что
      большую часть ролика ты будешь присутствовать в кадре и расскажешь о себе самостоятельно.
      Представь, будто ты разговариваешь с реальным собеседником, который сидит напротив.
      <br />
      <br />
      Можешь монтировать видео, вставлять изображения, анимацию или спецэффекты. Но не забывай, что
      важно не столько качество видео, сколько твой рассказ о себе. Даже простая видеовизитка,
      снятая одним дублем на камеру телефона, отлично подойдет, если рассказывает о таком прекрасном
      человеке, как ты.
      <br />
      <br />
      <Text $color='red'>Продолжительность видеовизитки – не более двух минут.</Text>
      <br />
      <Text $color='red'>
        Объем файла – не более 50 Мбайт. Но если ты понимаешь, что скорость/качество сети на твоем
        устройстве не достаточно хорошие, мы рекомендуем тебе уменьшить объем файла до 20 Мбайт –
        так вероятность его успешной загрузки будет намного выше.
      </Text>
      <br />
      <Text $color='red'>Формат файла – МP4.</Text>
      <br />
      <Text $color='red'>
        Уменьшить размер видео и изменить формат тебе поможет специальный сервис:{' '}
        <a href='https://video-converter.com/ru' target='_blank' rel='noreferrer'>
          https://video-converter.com/ru
        </a>{' '}
        или любой другой конвертер.
      </Text>
    </HeaderModalVideoText>
  );
};
