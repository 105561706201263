import React from 'react';

import { StyledModalAttachmentVideoStyled } from '../ModalAttachmentVideoStyled';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { Button } from '../../Button';
import { Box } from '../../Box';
import { Text } from '../../Text';
import { Icon } from '../../Icon';
import { useSelector } from 'react-redux';
import { selectUserInfo, selectScenarios } from 'src/seventhGrade/redux/user/userGetters';
import { ID, to } from 'src/seventhGrade/hooks/scenarioHelpers/ID';

export const VideoIsAttached = () => {
  const history = useHistory();
  const user = useSelector(selectUserInfo);
  const scenarioState = useSelector(selectScenarios);

  const handleNavigate = () => {
    if (
      user?.grade === 7 &&
      !scenarioState?.scenarios?.[to[ID.stage2_act1_mother]] &&
      scenarioState?.scenarios?.[to[ID.stage2_act1_arch]]?.at(-1)?.type === 'exit' &&
      !scenarioState?.activeChatScenario
    ) {
      history?.push({ pathname: routes.map, state: { from: 'profile' } });
    } else {
      history?.push(routes.chat);
    }
  };

  return (
    <>
      <StyledModalAttachmentVideoStyled>
        <div>
          <Text $textStyle='p18' $fontWeight='800'>
            Видеовизитка загружена! 🎉
          </Text>
          <div style={{ paddingTop: '20px' }}>
            <Text $textStyle='p18'>
              Ваша видеовизитка уже улетела на изучение обитателям парка 🎯
            </Text>
          </div>
        </div>
        <Button $textStyle='p18' $fontWeight='500' onClick={handleNavigate}>
          <Box $justify='space-between' $align='center'>
            Вернуться в игру
            <Icon $mL='32px' icon='ico_chevron' />
          </Box>
        </Button>
      </StyledModalAttachmentVideoStyled>
    </>
  );
};
