/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const scenario_stop_1: Scenario = {
  scenarioId: SceneId.scenario_stop_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors.CAT.name,
    person: Interlocutors.CAT,
    text: `Доступная часть игры пройдена. Впереди тебя ждут новые загадки и испытания! Возвращайся в игру 15 апреля.`,
    buttons: [],
    described: ``,
    buttonToExitTheGame: true,
  },
};
