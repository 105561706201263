/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from 'src/sixthGrade/resources/interlocutors';

export const olympicPark_1_1_0: Scenario = {
  scenarioId: SceneId.olympicPark_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: interlocutors.CAT.name,
    text: `Наконец-то познакомлю тебя с Петровым! И, надеюсь, он поможет нам выяснить, что же происходит в парке.`,
    buttons: ['Продолжить'],
  },
};
