import React from 'react';
import { QuestionButton, Step, StepType } from '../../types/scenario';
import ChatMessage from '../../components/chat/ChatMessage/ChatMessage';
import { IChatMessageContainerProps } from '../../components/chat/ChatMessage/ChatMessageTypes';
import styled from 'styled-components';
import { colors } from '../../../common/shared/constants/colors';
import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { ScenarioTimer } from '../ScenarioTimer';

import { selectGlobalTimer, selectTimer } from '../../../sixthGrade/redux/scenario/scenarioGetters';
import useViewport from '../../../sixthGrade/hooks/useViewport';

const StyledSystemMessage = styled.div`
  border-width: 1px;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  padding: 40px;
  color: ${colors.grey2};
`;

function MessageWrapper(props: IChatMessageContainerProps) {
  const { step } = props;

  const { isMobile } = useViewport();
  const messageTimer = useSelector(selectTimer);
  const globalTimer = useSelector(selectGlobalTimer);

  if (step.type === StepType.system && step.text) {
    return (
      <StyledSystemMessage>
        <ScenarioTimer
          isForChat
          isMobile={isMobile}
          messageTimer={messageTimer}
          globalTimer={globalTimer}
        />
        {step.text}
      </StyledSystemMessage>
    );
  }

  if (
    step.type === StepType.exit ||
    step.type === StepType.system ||
    step.type === StepType.to_map_step ||
    step.type === StepType.to_lk_step
  ) {
    return null;
  }

  return <ChatMessage {...props} />;
}

interface MessagesContainerProps {
  messages: Step[];
  lastStep: Step;
  variables: Record<string, string>;

  onTimeout: () => void;
  onMessageSent: (id: string) => void;
  onAnswer: (value: string | QuestionButton[]) => void;
}

export function MessagesContainer(props: MessagesContainerProps) {
  const { messages, variables, onMessageSent, lastStep, onTimeout, onAnswer } = props;
  const scenarios = useSelector(selectScenarios);

  return (
    <>
      {messages.map((step, index) => {
        return (
          <MessageWrapper
            renameMalevich={
              Boolean(scenarios?.scenarios?.scenario_3_19) ||
              scenarios?.activeChatScenario?.scenarioId === 'hermitage_1_1' ||
              scenarios?.activeChatScenario?.scenarioId === 'hermitageFeeding_1_1'
            }
            renameGuide={
              scenarios?.activeChatScenario?.scenarioId === 'hermitage_1_1' ||
              scenarios?.activeChatScenario?.scenarioId === 'hermitageFeeding_1_1'
            }
            onTimeout={onTimeout}
            variables={variables}
            key={index}
            onAnswer={onAnswer}
            onMessageSent={onMessageSent}
            step={step}
            nextStep={messages[index + 1]}
            previousStep={messages[index - 1]}
            lastStep={lastStep}
            isLast={index === messages.length - 1}
          />
        );
      })}
    </>
  );
}
