import * as checks from './stageChecks';
import { ID, to } from './ID';
import { PointsOfInterest } from '../../types';
import { SceneId } from '../../types/scenario';
import { createNavigateToPointLink, toArray } from './utils';
import { RunModalScenario, ScenarioHelperResponse } from './types';
import { ScenarioState } from '../../redux/scenario';

export function stage1(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId) => void,
  runModalScenario: RunModalScenario,
): ScenarioHelperResponse {
  /**
   * акт 0
   */
  if (checks.notCatWelcome(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.catWelcome],
        unsaved: true,
      },
    };
  }

  if (checks.notCityPresented(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.cityPresentation] },
    };
  }

  if (checks.notWelcomeTest(scenarioState)) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      helpMessage: `Иди в ${mguNavigatePoint} на карте парка «Большая перемена». Там тебя ждет кое-что интересное.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.mgu,
          handler: () =>
            runModalScenario(
              to[ID.welcomeTestIntro],
              to[ID.welcomeTest],
              to[ID.welcomeTest],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  /**
   * ==============   1 АКТ   ==============
   */
  const firstActHandlers: Partial<Record<PointsOfInterest, (() => void) | undefined>> = {};
  let universalHelpMessage;

  if (!scenarioState.scenarios?.[to[ID.baggage]]) {
    universalHelpMessage = createNavigateToPointLink('Домик бабули', PointsOfInterest.hersones);

    firstActHandlers[PointsOfInterest.hersones] = () =>
      runModalScenario(
        to[ID.baggageIntro],
        to[ID.baggage],
        to[ID.baggageComplete],
        scenarioState.scenarios,
      );
  }

  if (
    !scenarioState.scenarios?.[to[ID.welcomeGrandmother]] &&
    scenarioState.scenarios?.[to[ID.baggage]]
  ) {
    universalHelpMessage = createNavigateToPointLink('ЮНЕСКО (Байкал)', PointsOfInterest.lake);

    firstActHandlers[PointsOfInterest.lake] = () =>
      runModalScenario(
        to[ID.welcomeGrandmotherIntro],
        to[ID.welcomeGrandmother],
        to[ID.welcomeGrandmotherComplete],
        scenarioState.scenarios,
      );
  }

  // подсказка вжуха
  // const grandMotherWelcomeHandler = {
  //   [PointsOfInterest.lake]: () =>
  //     runModalScenario(
  //       to[ID.welcomeGrandmotherIntro],
  //       to[ID.welcomeGrandmother],
  //       to[ID.welcomeGrandmotherComplete],
  //       scenarioState.scenarios,
  //     ),
  // };

  // if (checks.notGrandmotherWelcome(scenarioState)) {
  //   const lakeNavigatePoint = createNavigateToPointLink(
  //     '«Природные достояния России»',
  //     PointsOfInterest.lake,
  //   );

  //   return {
  //     // eslint-disable-next-line max-len
  //     helpMessage: `Найди на карте парка «Большая перемена» горы и озеро с парусником. Это достопримечательность ${lakeNavigatePoint}. Тебе нужно идти туда.`,
  //     pointsHandlers: toArray({ ...grandMotherWelcomeHandler }),
  //   };
  // }

  // if (!scenarioState.scenarios?.[to[ID.welcomeTeacher]]) {
  //   firstActHandlers[PointsOfInterest.mgu] = () =>
  //     runModalScenario(
  //       to[ID.welcomeTeacherIntro],
  //       to[ID.welcomeTeacher],
  //       to[ID.welcomeTeacherComplete],
  //       scenarioState.scenarios,
  //     );
  // }

  // if (!scenarioState.scenarios?.[to[ID.welcomePetrov]]) {
  //   firstActHandlers[PointsOfInterest.space] = () =>
  //     runModalScenario(
  //       to[ID.welcomePetrovIntro],
  //       to[ID.welcomePetrov],
  //       to[ID.welcomePetrovComplete],
  //       scenarioState.scenarios,
  //     );
  // }
  // if (!scenarioState.scenarios?.[to[ID.welcomeEntrepreneur]]) {
  //   firstActHandlers[PointsOfInterest.ostankino] = () =>
  //     runModalScenario(
  //       to[ID.welcomeEntrepreneurIntro],
  //       to[ID.welcomeEntrepreneur],
  //       to[ID.welcomeEntrepreneurComplete],
  //       scenarioState.scenarios,
  //     );
  // }

  if (checks.notFirstActCompleted(scenarioState)) {
    return {
      helpMessage: `${universalHelpMessage}. Нажми на ссылку, чтобы перейти к новым заданиям и приготовься к тому, что тебе придётся разгадывать тайны и решать головоломки. 
        Удачи, отважный герой!`,
      pointsHandlers: toArray(firstActHandlers),
    };
  }

  // if (checks.notCatFlaskHelpIntro(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.catFlaskHelpIntro], runOnSubmit: to[ID.catFlaskHelp] },
  //   };
  // }

  // if (checks.notCatFlaskHelp(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.catFlaskHelp], unsaved: true },
  //   };
  // }

  // const grandMomWelcomeHandler = {
  //   [PointsOfInterest.lake]: () =>
  //     runModalScenario(
  //       to[ID.welcomeGrandMomIntro],
  //       to[ID.welcomeGrandMom],
  //       to[ID.welcomeGrandMomComplete],
  //       scenarioState.scenarios,
  //     ),
  // };

  // if (checks.notGrandMomWelcome(scenarioState)) {
  //   const lakeNavigatePoint = createNavigateToPointLink(
  //     '«Природные достояния России»',
  //     PointsOfInterest.lake,
  //   );

  //   return {
  //     // eslint-disable-next-line max-len
  //     helpMessage: `Найди на карте парка «Большая перемена» горы и озеро с парусником. Это достопримечательность ${lakeNavigatePoint}. Тебе нужно идти туда.`,
  //     pointsHandlers: toArray({ ...grandMomWelcomeHandler }),
  //   };
  // }

  /**
   * ==============   2 АКТ   ==============
   */
  const secondActHandlers: Partial<Record<PointsOfInterest, (() => void) | undefined>> = {};

  /**
   * олимпийский парк
   *
   * открывается после озера
   */

  if (
    !scenarioState.scenarios?.[to[ID.sochiInfo]] &&
    scenarioState.scenarios?.[to[ID.welcomeGrandmother]]
  ) {
    secondActHandlers[PointsOfInterest.sochi] = () =>
      runModalScenario(
        to[ID.sochiIntro],
        to[ID.sochiInfo],
        to[ID.sochiInfoComplete],
        scenarioState.scenarios,
      );
  }

  if (
    checks.notSecondActSochiCompleted(scenarioState) &&
    scenarioState.scenarios?.[to[ID.welcomeGrandmother]]
  ) {
    // Для линейности и правильного отображения подсказок Вжуха
    const customSecondActHandlers = {
      ...secondActHandlers,
    };

    const sochiNavigatePoint = createNavigateToPointLink(
      'Олимпийский парк',
      PointsOfInterest.sochi,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Иди в ${sochiNavigatePoint} на карте парка «Большая перемена». Он находится справа от Космодрома на берегу реки.`,
      pointsHandlers: toArray({
        ...customSecondActHandlers,
      }),
    };
  }

  /**
   * МГУ
   */

  if (
    !scenarioState.scenarios?.[to[ID.mguSecondAct]] &&
    scenarioState.scenarios?.[to[ID.sochiInfo]]
  ) {
    secondActHandlers[PointsOfInterest.mgu] = () =>
      runModalScenario(
        to[ID.mguSecondAct],
        to[ID.mguSecondAct],
        to[ID.mguSecondActComplete],
        scenarioState.scenarios,
      );
  }

  /**
   * подсказка до МГУ
   */
  if (
    checks.notSecondActMguCompleted(scenarioState) &&
    scenarioState.scenarios?.[to[ID.sochiInfo]]
  ) {
    const mguSecondActHandlers = {
      ...secondActHandlers,
    };

    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Возвращайся в ${mguNavigatePoint} на карте парка «Большая перемена».`,
      pointsHandlers: toArray({
        ...mguSecondActHandlers,
      }),
    };
  }

  /**
   * эрмитаж NOT USED
   */
  // if (!scenarioState.scenarios?.[to[ID.hermitageSecondAct]] && scenarioState.scenarios?.[to[ID.mguSecondAct]]) {
  //   secondActHandlers[PointsOfInterest.hermitage] = () =>
  //     runModalScenario(
  //       to[ID.hermitageSecondAct],
  //       to[ID.hermitageSecondAct],
  //       to[ID.hermitageSecondActComplete],
  //       scenarioState.scenarios,
  //     );
  // }

  /**
   * МГУ (возвращение в ботанический сад)  NOT USED
   */
  // if (!scenarioState.scenarios?.[to[ID.mguGardenSecondAct]] && scenarioState.scenarios?.[to[ID.hermitageSecondAct]]) {
  //   secondActHandlers[PointsOfInterest.mgu] = () =>
  //     runModalScenario(
  //       to[ID.mguGardenSecondAct],
  //       to[ID.mguGardenSecondAct],
  //       to[ID.mguGardenSecondActComplete],
  //       scenarioState.scenarios,
  //     );
  // }

  /**
   * ==============   3 АКТ   ==============
   */

  const thirdActHandlers: Partial<Record<PointsOfInterest, (() => void) | undefined>> = {};

  /**
   * МГУ
   */
  if (
    !scenarioState.scenarios?.[to[ID.mguTeacherThirdAct]] &&
    scenarioState.scenarios?.[to[ID.mguSecondAct]]
  ) {
    thirdActHandlers[PointsOfInterest.mgu] = () =>
      runModalScenario(
        to[ID.mguTeacherThirdAct],
        to[ID.mguTeacherThirdAct],
        to[ID.mguTeacherThirdActComplete],
        scenarioState.scenarios,
      );
  }

  /**
   * подсказка до МГУ
   */
  if (
    !scenarioState.scenarios?.[to[ID.mguTeacherThirdAct]] &&
    scenarioState.scenarios?.[to[ID.mguSecondAct]]
  ) {
    const mguThirdActHandlers = {
      ...thirdActHandlers,
    };

    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Возвращайся в ${mguNavigatePoint} на карте парка «Большая перемена».`,
      pointsHandlers: toArray({
        ...mguThirdActHandlers,
      }),
    };
  }

  /**
   * Москва-Сити
   */

  if (
    !scenarioState.scenarios?.[to[ID.moscowCityThirdAct]] &&
    scenarioState.scenarios?.[to[ID.mguTeacherThirdAct]]
  ) {
    thirdActHandlers[PointsOfInterest.moscowCity] = () =>
      runModalScenario(
        to[ID.moscowCityThirdAct],
        to[ID.moscowCityThirdAct],
        to[ID.moscowCityThirdActComplete],
        scenarioState.scenarios,
      );
  }

  /**
   * подсказка до Москва-Сити
   */
  if (
    !scenarioState.scenarios?.[to[ID.moscowCityThirdAct]] &&
    scenarioState.scenarios?.[to[ID.mguTeacherThirdAct]]
  ) {
    const moscowCityThirdActHandlers = {
      ...thirdActHandlers,
    };

    const navigatePoint = createNavigateToPointLink('Москва-Сити', PointsOfInterest.moscowCity);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Иди в ${navigatePoint} на карте парка «Большая перемена».`,
      pointsHandlers: toArray({
        ...moscowCityThirdActHandlers,
      }),
    };
  }

  /**
   * Парк Патриот
   */
  if (
    !scenarioState.scenarios?.[to[ID.patriotThirdAct]] &&
    scenarioState.scenarios?.[to[ID.moscowCityThirdAct]]
  ) {
    thirdActHandlers[PointsOfInterest.patriot] = () =>
      runModalScenario(
        to[ID.patriotThirdAct],
        to[ID.patriotThirdAct],
        to[ID.patriotThirdActComplete],
        scenarioState.scenarios,
      );
  }

  /**
   * подсказка до Парк Патриот
   */
  if (
    !scenarioState.scenarios?.[to[ID.patriotThirdAct]] &&
    scenarioState.scenarios?.[to[ID.moscowCityThirdAct]]
  ) {
    const patriotThirdActHandlers = {
      ...thirdActHandlers,
    };

    const navigatePoint = createNavigateToPointLink('Парк Патриот', PointsOfInterest.patriot);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Иди в ${navigatePoint} на карте парка «Большая перемена».`,
      pointsHandlers: toArray({
        ...patriotThirdActHandlers,
      }),
    };
  }

  /**
   * конец 1 этапа
   */

  //   if (checks.notFogStarted(scenarioState)) {
  //     const lakeNavigatePoint = createNavigateToPointLink(
  //       'Природные достояния России',
  //       PointsOfInterest.lake,
  //     );

  //     return {
  //       pointsHandlers: toArray({
  //         [PointsOfInterest.lake]: () => runScenario(to[ID.grandMomThirdAct]),
  //       }),
  //       helpMessage: `{{NAME}}, как ты помнишь, я больше всех брожу по парку и знаю почти обо всем, что происходит.

  // Если понадобится помощь, кликни на меня и я расскажу, что знаю.

  // Например, сейчас тебе стоит сходить в ${lakeNavigatePoint}.`,
  //     };
  //   }
  //   if (checks.notFogStarted(scenarioState)) {
  //     return {
  //       pointsHandlers: toArray({
  //         [PointsOfInterest.lake]: () => runScenario(to[ID.grandMomThirdAct]),
  //       }),
  //     };
  //   }
  //   if (checks.notFogIntro(scenarioState)) {
  //     return {
  //       pointsHandlers: [],
  //       autorunScenario: { id: to[ID.fogIntro], runOnSubmit: to[ID.teacherLost] },
  //     };
  //   }
  //   if (checks.notStartTeacherHelp(scenarioState)) {
  //     return {
  //       pointsHandlers: [],
  //       autorunScenario: { id: to[ID.teacherLost], unsaved: true },
  //     };
  //   }
  //   const helpPoint = {
  //     [PointsOfInterest.mgu]: () => runScenario(to[ID.helpTeacher]),
  //   };
  //   if (checks.notChosenHelpVariant(scenarioState)) {
  //     const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

  //     return {
  //       pointsHandlers: toArray(helpPoint),
  //       helpMessage: `{{NAME}}, скорее беги в ${mguNavigatePoint}!`,
  //     };
  //   }
  //   if (checks.notGotRequiredRopeStair(scenarioState)) {
  //     const sochiNavigatePoint = createNavigateToPointLink(
  //       'Олимпийском парке',
  //       PointsOfInterest.sochi,
  //     );

  //     return {
  //       helpMessage: `Светлана Васильевна в беде! {{NAME}}, нужно найти Сан Саныча в ${sochiNavigatePoint}.`,
  //       pointsHandlers: toArray({
  //         ...helpPoint,
  //         [PointsOfInterest.sochi]: () => runScenario(to[ID.getRopeStair]),
  //       }),
  //     };
  //   }

  //   if (checks.notTeacherSaved(scenarioState)) {
  //     const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

  //     return {
  //       pointsHandlers: toArray(helpPoint),
  //       helpMessage: `{{NAME}}, вам с Сан Санычем лучше поторопиться обратно в ${mguNavigatePoint}.`,
  //     };
  //   }

  // if (checks.notFirstStageCompleted(scenarioState)) {
  //   const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

  //   const pointsHandlers = {
  //     [PointsOfInterest.mgu]: () => runScenario(to[ID.teacherLastTest]),
  //   };
  //   return {
  //     pointsHandlers: toArray(pointsHandlers),
  //     helpMessage: `{{NAME}}, кажется, тебе надо сходить в ${mguNavigatePoint}.`,
  //   };
  // }
  return {
    pointsHandlers: [],
  };
}
