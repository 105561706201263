/* eslint-disable max-len */
import {
  Action,
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { TEST_TVOI_VIBOR_1 } from './test';

const EXIT_ID = 'EXIT';
const person = Interlocutors.TEACHER;
const TO_LK_STEP = 'TO_LK_STEP';

export const scenario_1_3_1: Scenario = {
  scenarioId: SceneId.scenario_1_3_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'МГУ',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к макету главного здания Московского Государственного Университета. 

    Удивительно, но каждый шаг навстречу зданию ощущается легче предыдущего. В какой-то момент кажется, что вы вот-вот оторветесь от земли и полетите.
    
    У входа в университет стоит задумчивая  женщина в очках. Вы начинаете с ней разговор..`,
    buttons: ['Начать'],
  },
};

const ABOUT_MGU: Record<string, Step> = {
  Q18: {
    type: StepType.question,
    person: Interlocutors.TEACHER,
    id: 'Q18',
    nextStep: 'Q19',
    maxAnswers: 1,
    text: `Мы находимся в копии Московского государственного университета имени М. В. Ломоносова, если коротко: МГУ. Несмотря на то, что это копия, студенты здесь тоже постигают науки. МГУ — один из старейших и крупнейших университетов России и расположен он в столице нашей родины.

    Впервые МГУ открыл двери для студентов в 1755 году - только подумать, как давно! И представь себе, в МГУ учится больше 40 000 студентов, такой огромный вуз!`,
    buttons: [
      {
        id: 'b1',
        text: 'Ух ты, как здорово! Но что же тут случилось?',
        nextStep: 'Q19',
      },
    ],
  },
};

export const scenario_1_3: Scenario = {
  scenarioId: SceneId.scenario_1_3,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_3',
    startId: 'Q17',
    person: Interlocutors.TEACHER,
    steps: {
      Q17: {
        type: StepType.message,
        id: 'Q17',
        person: Interlocutors.TEACHER,
        text: `Добрый день! А я тебя уже знаю. Ты {{NAME}}, верно? Меня зовут Светлана Васильевна, я учительница 👩‍🏫 

        Как и ты, я здесь недавно. Меня пригласили обучать обитателей парка наукам. Я с детства мечтала быть учителем. И вот моя мечта сбылась! Обожаю познавать мир и рассказывать ученикам про науки, способные изменить жизнь человечества к лучшему! 😍
        
        Сейчас, правда, мне немного грустно, потому что после нашествия тумана больше некого учить ☹️`,
        nextStep: 'Q17_0',
      },
      Q17_0: {
        type: StepType.question,
        id: 'Q17_0',
        person: Interlocutors.TEACHER,
        text: `Думаю, с твоей помощью мы разберемся, что произошло, вернем учеников в парк и выясним, почему из парка исчезли идеи.`,
        maxAnswers: 1,
        callToAction: 'Выбери вариант',
        buttons: [
          {
            id: 'b1',
            text: 'Приятно познакомиться, Светлана Васильевна! А расскажите, где это мы?',
            nextStep: 'Q18',
          },
          {
            id: 'b2',
            text: 'Приятно познакомиться, но мой путь лежал не сюда, извините…',
            nextStep: 'Q17_1',
          },
        ],
      },
      Q17_1: {
        type: StepType.question,
        id: 'Q17_1',
        person: Interlocutors.TEACHER,
        text: `Неужели тебе не интересно посмотреть на летающие книги?`,
        nextStep: 'Q19',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну, ладно. Интересно, почему они летают. Давно это началось? ',
            nextStep: 'Q19',
          },
        ],
      },
      ...ABOUT_MGU,
      Q19: {
        type: StepType.message,
        id: 'Q19',
        person: Interlocutors.TEACHER,
        text: `Видишь ли, несколько дней назад я шла в библиотеку МГУ, чтобы подготовить домашнее задание для ребят. Но когда я вошла, все книги, как по волшебству, летали в воздухе.

        Пойдём внутрь, покажу тебе эти чудеса.`,
        nextStep: 'Q20',
      },
      Q20: {
        type: StepType.question,
        person: Interlocutors.TEACHER,
        id: 'Q20',
        text: `Хорошо хоть, стеллажи прикручены к полу! А то бы тоже улетели 🙀`,
        nextStep: 'Q21',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, держись крепче! ',
            nextStep: 'Q21',
          },
        ],
      },
      Q21: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q21',
        person: Interlocutors.TEACHER,
        text: `{{name}}, тут нам не обойтись без твоей помощи. Я отпустила всех ребят по домам, но уже скоро нам нужно провести здесь урок. А для этого понадобятся учебники. 

        Поможешь мне поймать их?`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q22',
            text: 'Конечно, давайте скорее приступим!',
          },
        ],
      },
      Q22: {
        type: StepType.question,
        person: Interlocutors.TEACHER,
        id: 'Q22',
        text: `Спасибо, что ты нам помогаешь! А расскажи о своих любимых предметах в школе. Соберём сначала учебники по ним.`,
        nextStep: 'QTest',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'QTest',
            text: 'Конечно, давайте скорее приступим!',
          },
        ],
      },

      QTest: TEST_TVOI_VIBOR_1,

      ...splitStep({
        type: StepType.question,
        id: 'Q23',
        person,
        text: `Как интересно! Книги по этим предметам самые красивые — в красных обложках.
        
        Держи сачок! Так нам легче будет ловить.`,
        nextStep: 'Q23_1',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q23_1',
            text: 'Спасибо! А давайте — кто быстрее всё поймает?',
          },
        ],
      }),

      Q23_1: {
        id: 'Q23_1',
        person: Interlocutors.SYSTEM,
        type: StepType.system,
        text: `Вы и Светлана Васильевна ловите сачками красные книги, летающие под потолком библиотеки. Вжух помогает складывать их в аккуратные стопки и убирать в старинный шкаф со стеклянными дверями.`,
        _meta: {
          type: SystemStepType.limitedTimeInput,
          time: 60,
          nextStep: 'Q24',
          exitStep: 'Q24',
        },
        isTimerDuplicate: true,
      },
      Q24: {
        type: StepType.question,
        id: 'Q24',
        person,
        text: `Всё готово! 

        {{name}}, Вжух, спасибо вам большое-пребольшое за помощь!`,
        nextStep: 'intro',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'intro',
            text: 'Пожалуйста! Это было даже весело.',
          },
        ],
      },
      // загрузка видеовизитки
      intro: {
        type: StepType.message,
        id: 'intro',
        person: Interlocutors.TEACHER,
        text: `{{NAME}}, благодаря твоей самоотверженной помощи я узнала тебя получше! Но другие обитатели Парка тоже хотели бы с тобой познакомиться, поэтому мы все просим тебя рассказать нам о себе - о твоих увлечениях, хобби, интересах по жизни. 

        Зайди в свой профиль и внимательно прочитай и выполни задание - и после этого возвращайся сюда!`,
        callToAction: 'Отправь видеовизитку и возвращайся сюда.',
        nextStep: 'video',
      },
      video: {
        type: StepType.system,
        condition: {
          type: Conditions.IS_VIDEO_ATTACHED,
          variants: ['extra', TO_LK_STEP],
        },
        id: 'video',
        person: Interlocutors.SYSTEM,
        _meta: {},
      },
      [TO_LK_STEP]: {
        person: Interlocutors.CAT,
        id: 'lkStep',
        type: StepType.to_lk_step,
      },
      extra: {
        type: StepType.question,
        id: 'extra',
        person: Interlocutors.TEACHER,
        text: `{{NAME}}, как здорово! Теперь все обитатели Парка узнают о тебе больше! И спасибо тебе и Вжуху еще раз за помощь с книгами!`,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q25',
            text: 'Всегда пожалуйста!',
          },
        ],
      },
      Q25: {
        type: StepType.question,
        id: 'Q25',
        person: Interlocutors.CAT,
        text: `Рады были помочь, но нам уже пора идти дальше. У нас же расследование.`,
        nextStep: EXIT_ID,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'До свидания, Светлана Васильевна! ',
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
