import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const lake_1_1_0: Scenario = {
  scenarioId: SceneId.lake_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Видишь, что в библиотеке происходит? Чудеса, да и только! Ничего не понятно, но очень интересно.

    Пойдём теперь к озеру Байкал, спросим у живущей там Бабули, не видела ли она ничего странного.`,
    buttons: ['Начать'],
  },
};

export const lake_1_1_1: Scenario = {
  scenarioId: SceneId.lake_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'ЮНЕСКО (Байкал)',
    text: `Вы подходите к достопримечательности «Природные достояния России» и полной грудью вдыхаете прекрасный свежий воздух! 

    На первый взгляд ничего необычного тут не происходит. 
    
    У берега озера стоит задорная старушка и кормит белок орешками. Вы начинаете с ней разговор...`,
    buttons: ['Начать'],
  },
};
