/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from 'src/sixthGrade/resources/interlocutors';

export const alien_1_1_0: Scenario = {
  scenarioId: SceneId.alien_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: interlocutors.CAT.name,
    text: `Делов-то осталось — всего лишь забрать у Сан Саныча эту «Иератаб Йонченлос Ялд Илатед». И узнаем, наконец-то, что в парке происходит.`,
    buttons: ['Продолжить'],
  },
};
