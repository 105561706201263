/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../types/scenario';
import interlocutors from '../../interlocutors';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';
const TO_LK_STEP = 'TO_LK_STEP';

const person = Interlocutors.SYSTEM;

// export const scenario_1_8_0: Scenario = {
//   scenarioId: SceneId.scenario_1_8_0,
//   type: ScenarioType.modal,
//   data: {
//     closable: true,
//     title: 'Космодром',
//     person: Interlocutors.SYSTEM,
//     text: `Вы подходите к макету космодрома «Восточный» в натуральную величину. Даже удивительно как он поместился в этом парке.

// На платформе стоит величественная ракета, а у входа в здание сидит чем-то увлеченный парень. Вы начинаете с ним разговор...`,
//     buttons: ['Начать'],
//   },
// };

export const scenario_1_8_0: Scenario = {
  scenarioId: SceneId.scenario_1_8_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Домик бабули',
    person: Interlocutors.SYSTEM,
    text: `Ты находишься в домике бабули. Возможно, стоит осмотреться здесь — может быть ты найдешь какие-то подсказки.`,
    buttons: ['Начать'],
  },
};

const TEST_TVOI_VECTOR_1: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'Q6',
  name: 'Твой вектор 1',
  nextStep: 'Q7_0',
  order: [
    {
      description:
        'С помощью твоих ответов мы определим, какие направления будут главными для изучения в университете. Отвечай честно и выбирай только то, что тебе близко.',
      subtest: 'T1',
      question: 'Q39',
    },
    {
      description: 'Теперь давай выберем, что студенты будут узнавать на дополнительных занятиях.',
      subtest: 'T1',
      question: 'Q16',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q17',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q11',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q10',
    },
    {
      description: 'Ну и последний вопрос просто о тебе.',
      subtest: 'T1',
      question: 'Q21',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person: Interlocutors.GRANDMOTHER,
  type: StepType.test,
};

const TEST_TVOI_KOD_2: TestStep = {
  external_id: 'test-tvoi-kod-junior-210401',
  id: 'Q2_5',
  name: 'Твой код 2',
  nextStep: 'intro',
  order: [
    {
      description:
        'Ты можешь выбрать несколько вариантов из предложенных. Нужно из восьми вариантов минимум два ответить «Точно да» и два «Определенно нет».',
      subtest: 'T2',
      question: 'Q1',
    },
    //     {
    //       description: `Хоть на уроках я не бывал, но во время дискуссии веду себя так же.

    // А здесь уже чуть посложнее, так как вариантов восемь. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».`,
    //       subtest: 'T2',
    //       question: 'Q1',
    //     },
    //     {
    //       description:
    //         'Ну все, последний вопрос. Снова восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
    //       subtest: 'T2',
    //       question: 'Q2',
    //     },
  ],
  path: 'test-tvoi-kod-junior-210401.json',
  person,
  type: StepType.test,
};

const TEST_TVOI_VIBOR_2: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'Q9',
  name: 'Твой выбор 1/5',
  nextStep: 'Q10',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных.',
      subtest: 'T2',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

const TEST_TVOI_VIBOR_3: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'Q14',
  name: 'Твой выбор 1/5',
  nextStep: 'Q15',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных.',
      subtest: 'T2',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
};

// export const scenario_1_8: Scenario = {
//   scenarioId: SceneId.scenario_1_8,
//   type: ScenarioType.chat,
//   isRequired: true,
//   data: {
//     title: 'Космодром',
//     scene_id: 'scenario_1_8',
//     startId: 'Q1',
//     steps: {
//       Q1: {
//         type: StepType.message,
//         id: 'Q1',
//         person,
//         text:
//           'Привет! Ты же {{NAME}}, верно? А меня Петров зовут. Я местный изобретатель и инженер 👨‍🔬',
//         nextStep: 'Q1_2',
//       },
//       Q1_2: {
//         type: StepType.question,
//         maxAnswers: 1,
//         id: 'Q1_2',
//         person,
//         text: 'Вжух мне про тебя рассказывал. Рад наконец-то познакомиться лично.',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Спасибо, и мне приятно познакомиться',
//             nextStep: 'Q2_1',
//           },
//           {
//             id: 'b2',
//             text: 'Да-да, а что тут происходит?',
//             nextStep: 'Q2_1',
//           },
//           {
//             id: 'b3',
//             text: 'Ой, не та дверь',
//             nextStep: TO_MAP_STEP,
//           },
//         ],
//       },
//       Q2_1: {
//         id: 'Q2_1',
//         person,
//         type: StepType.question,
//         maxAnswers: 1,
//         text:
//           'Ты же уже знаешь о том, что произошло в парке, верно? Из-за этого тумана все электронные замки на космодроме начали барахлить, и сотрудникам ничего не оставалось делать, как разойтись по домам. Вот пытаюсь починить замок и попасть внутрь 🙁',
//         callToAction: 'Поможешь?',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Да, поехали!',
//             nextStep: 'Q3',
//           },
//           {
//             id: 'b2',
//             text: 'Нет, извини, я пока не могу',
//             nextStep: TO_MAP_STEP,
//           },
//         ],
//       },
//       Q3: {
//         person,
//         type: StepType.message,
//         id: 'Q3',
//         nextStep: 'Q3_2',
//         text:
//           'А тебе известно, что «Поехали!» — слово, которое произнес первый космонавт во время старта первого пилотируемого космического корабля? 👨‍🚀',
//       },
//       Q3_2: {
//         person,
//         type: StepType.message,
//         id: 'Q3_2',
//         nextStep: 'Q3_3',
//         text:
//           'Этим космонавтом был наш соотечественник Юрий Гагарин, а произошло это 12 апреля 1961 года. Уже целых 60 лет назад, только подумать!',
//       },
//       Q3_3: {
//         person,
//         type: StepType.question,
//         maxAnswers: 1,
//         id: 'Q3_3',
//         nextStep: 'Q3_2',
//         text:
//           'Юрий Гагарин на космическом корабле «Восток-1» стартовал с космодрома Байконур. Кстати, мы сейчас как раз находимся в похожем космодроме. Гагарин впервые в мире совершил орбитальный облет планеты Земля. Время его нахождения в космосе составило почти 2 часа 🚀',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Вот это да! Спасибо за новую информацию.',
//             nextStep: 'Q4',
//           },
//           {
//             id: 'b2',
//             text: 'А я об этом знаю. Наверняка было очень круто!',
//             nextStep: 'Q4',
//           },
//         ],
//       },
//       Q4: {
//         type: StepType.message,
//         id: 'Q4',
//         person,
//         text: 'Класс! Тогда давай к делу.',
//         nextStep: 'Q5',
//       },
//       Q5: {
//         type: StepType.message,
//         id: 'Q5',
//         person,
//         text:
//           'Знаешь, здесь я видел людей разного толка. Кто-то изучает технологии, а кто-то жизнь. Так много специалистов работают совместно над большой задачей.',
//         nextStep: 'Q5_1',
//       },
//       Q5_1: {
//         type: StepType.question,
//         maxAnswers: 1,
//         id: 'Q5_1',
//         person,
//         text: 'Поделись, а с чем бы тебе было интересно работать?',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Ок',
//             nextStep: 'Q6',
//           },
//         ],
//       },
//       Q6: TEST_TVOI_VIBOR,
//       Q7: {
//         type: StepType.message,
//         id: 'Q7',
//         person,
//         text: '{{NAME}}, ты очень интересный человек и я верю, тебя ждет большое будущее 😉',
//         nextStep: 'Q7_1',
//       },
//       Q7_1: {
//         type: StepType.message,
//         id: 'Q7_1',
//         person,
//         text:
//           'Так, электронный замок перезагружается, это займет какое-то время. Приходи позже. Увидимся!',
//         nextStep: EXIT_ID,
//       },
//       [EXIT_ID]: {
//         person,
//         id: EXIT_ID,
//         type: StepType.exit,
//       },
//       [TO_MAP_STEP]: {
//         person,
//         id: TO_MAP_STEP,
//         type: StepType.to_map_step,
//       },
//     },
//   },
// };

export const scenario_1_8_1: Scenario = {
  scenarioId: SceneId.scenario_1_8_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors.SYSTEM.name,
    person,
    text: 'А, это снова ты. Все еще не готово. Приходи позже.',
    buttons: ['Закрыть'],
  },
};

export const scenario_1_8: Scenario = {
  scenarioId: SceneId.scenario_1_8,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Домик бабули',
    scene_id: 'scenario_1_8',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person: Interlocutors.CAT,
        text: '{{NAME}}, давай разберем твой багаж — ответь на несколько вопросов, возможно, они помогут нам найти какие-то зацепки.',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай',
            nextStep: 'Q1_2',
          },
        ],
      },
      // Q1_2: {
      //   type: StepType.question,
      //   maxAnswers: 1,
      //   id: 'Q1_2',
      //   person: Interlocutors.CAT,
      //   text: 'Смотри, тут много вещей — что стоит изучить?',
      //   callToAction: 'Выбери предмет',
      //   buttons: [
      //     {
      //       id: 'b1',
      //       text: 'Посмотреть фотографию',
      //       nextStep: 'Q2_1',
      //     },
      //     {
      //       id: 'b2',
      //       text: 'Открыть ноутбук',
      //       nextStep: 'Q2_3',
      //     },
      //     {
      //       id: 'b3',
      //       text: 'Взять банку с вареньем',
      //       nextStep: 'Q2_2',
      //     },
      //     {
      //       id: 'b4',
      //       text: 'Осмотреть миску',
      //       nextStep: 'Q2_4',
      //     },
      //   ],
      // },
      Q1_2: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1_2',
        person: Interlocutors.CAT,
        text: 'Смотри, тут много вещей — что стоит изучить?',
        callToAction: 'Выбери предмет',
        buttons: [
          {
            id: 'b1',
            text: 'Посмотреть фотографию',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Открыть ноутбук',
            nextStep: 'Q2_3',
          },
          {
            id: 'b3',
            text: 'Взять банку с вареньем',
            nextStep: 'Q2_2',
          },
          {
            id: 'b4',
            text: 'Осмотреть миску',
            nextStep: 'Q2_4',
          },
        ],
      },
      Q2_1: {
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: 'На фотографии ты и бабуля на фоне дома, закрытого яблонями. На лавочке рядом с домом спит упитанный кот. Ты щуришься от летнего солнца. Подпись на фото «Я и бабуля. 2021 год».',
        callToAction: 'Посмотреть фотографию',
        buttons: [
          {
            id: 'b1',
            text: 'Открыть ноутбук',
            nextStep: 'Q2_3',
          },
          {
            id: 'b2',
            text: 'Осмотреть миску',
            nextStep: 'Q2_4',
          },
          {
            id: 'b3',
            text: 'Взять банку с вареньем',
            nextStep: 'Q2_2',
          },
        ],
      },
      Q2_2: {
        id: 'Q2_2',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Это банка айвового варенья, внутри на солнце переливается золотыми красками вязкая масса варенья с аккуратными дольками айвы. На варенье наклеена этикетка, где аккуратным почерком написано «Для {{NAME}}».',
        callToAction: 'Взять банку с вареньем',
        buttons: [
          {
            id: 'b1',
            text: 'Посмотреть фотографию',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Открыть ноутбук',
            nextStep: 'Q2_3',
          },
          {
            id: 'b3',
            text: 'Осмотреть миску',
            nextStep: 'Q2_4',
          },
        ],
      },
      Q2_3: {
        id: 'Q2_3',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ты открываешь ноутбук. На экране высвечиваются два окошка.',
        callToAction: 'Выберите пользователя',
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать пользователя {{NAME}}',
            nextStep: 'Q2_3_1',
          },
          {
            id: 'b2',
            text: 'Выбрать пользователя Х',
            nextStep: 'Q2_3_2',
          },
        ],
      },
      Q2_3_1: {
        id: 'Q2_3_1',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ты нажимаешь на окошко со своим именем. На экране ты обнаруживаешь множество сохраненных картинок и папку «личное». Может быть, пришло время узнать о себе немного больше?',
        callToAction: 'Выбрать пользователя {{NAME}}',
        buttons: [
          {
            id: 'b1',
            text: 'Открыть папку «личное»',
            nextStep: 'Q2_5',
          },
        ],
      },
      Q2_3_2: {
        id: 'Q2_3_2',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ты выбираешь пользователя Х и видишь сообщение «введите пароль». «Хм, у меня пока нет пароля», — думаешь ты. Возможно, я найду его позже.',
        callToAction: 'Выбрать пользователя Х',
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать пользователя {{NAME}}',
            nextStep: 'Q2_3_1',
          },
        ],
      },
      Q2_4: {
        id: 'Q2_4',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ты берешь в руки миску, на ней изображены следы кошачьих лапок, улыбающаяся мордочка и выгравировано имя «Вжух». «Так вот, как тебя зовут — Вжух»!',
        callToAction: 'Осмотреть миску',
        buttons: [
          {
            id: 'b1',
            text: 'Открыть ноутбук',
            nextStep: 'Q2_3',
          },
          {
            id: 'b2',
            text: 'Посмотреть фотографию',
            nextStep: 'Q2_1',
          },
          {
            id: 'b3',
            text: 'Взять банку с вареньем',
            nextStep: 'Q2_2',
          },
        ],
      },
      Q2_5: TEST_TVOI_KOD_2, // Тест: Твой код (тип личности) 2
      // видеовизитка
      intro: {
        type: StepType.message,
        id: 'intro',
        person: Interlocutors.SYSTEM,
        text: '{{NAME}}, обитатели парка хотят узнать о тебе кое-что еще. Запиши, пожалуйста, видеовизитку. Подробности этого задания ты увидишь в своем профиле.',
        callToAction: 'Загрузи видеовизитку и возвращайся',
        nextStep: 'video',
      },
      video: {
        type: StepType.system,
        condition: {
          type: Conditions.IS_VIDEO_ATTACHED,
          variants: ['Q2_6', TO_LK_STEP],
        },
        id: 'video',
        person: Interlocutors.SYSTEM,
        _meta: {},
      },
      [TO_LK_STEP]: {
        person: Interlocutors.CAT,
        id: 'lkStep',
        type: StepType.to_lk_step,
      },
      Q2_6: {
        id: 'Q2_6',
        person,
        type: StepType.message,
        text: 'Ты аккуратно укладываешь ноутбук в свой рюкзак.',
        callToAction: 'ПОЛУЧЕН ПРЕДМЕТ «НОУТБУК»',
        nextStep: 'Q2_7',
      },
      Q2_7: {
        id: 'Q2_7',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Теперь мы готовы выдвигаться. Предлагаю найти бабулю!',
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в порт',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

/**
 *  знакомство с бабулей
 */

export const scenario_1_8_4: Scenario = {
  scenarioId: SceneId.scenario_1_8_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'ЮНЕСКО',
    person: Interlocutors.SYSTEM,
    text: `Вы с Вжухом приходите на берег моря, на берегу стоят таврические колонны, словно оставшиеся развалины древнего мира, где-то вдалеке ты слышишь крик. Обернувшись, ты обнаруживаешь старый маяк, а наверху в разные стороны вращается лампа. Ты забегаешь в него, распахивая старую деревянную дверь и поднимаешься по лестнице.`,
    buttons: ['Начать'],
    // closable: false,
    // title: 'Тишина',
    // person: Interlocutors.SYSTEM,
    // text: `В парке все спят. Возвращайся в парк 25 апреля. А пока не забывай делать уроки и побольше проводи времени на свежем воздухе.`,
    // buttons: [],
  },
};

export const scenario_1_8_5: Scenario = {
  scenarioId: SceneId.scenario_1_8_5,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: '',
    scene_id: 'scenario_1_8_5',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person: Interlocutors.GRANDMOTHER,
        text: `Ох, как хорошо, что вы здесь. Не понимаю, что происходит. Я пыталась открыть дверь, но она была заперта снаружи. 
          А кто вы такие?
          `,
        callToAction: 'Выбери вариант',
        buttons: [
          {
            id: 'b1',
            text: 'Это я, бабуля, а это Вжух — твой кот!',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        type: StepType.message,
        id: 'Q2',
        person: Interlocutors.GRANDMOTHER,
        text: `«Ох, совсем ничего не помню. Значит, я бабуля. Так-так, это уже многое объясняет», —  бабушка смотрит на свои руки.

        К тому же, сегодня по невнимательности я надела не те очки и совсем не могу разглядеть тебя 😣
        
        Опиши себя, пожалуйста, чтобы я хоть представила, как ты выглядишь.`,
        nextStep: 'Q2/1',
      },
      'Q2/1': {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q2/1',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Зайди в свой профиль и создай там персонажа 😊 Если ты уже [[создал/создала]] персонажа в своем профиле и тебя все устраивает, то измени хотя бы цвет фона, иначе без нужных очков я все равно тебя не увижу.',
        callToAction: 'Сделай и возвращайся',
        buttons: [
          {
            id: 'b1',
            text: 'Готово',
            nextStep: 'Q2/2',
          },
        ],
      },
      'Q2/2': {
        type: StepType.system,
        condition: {
          type: Conditions.IS_PROFILE_BACKGROUND_CHANGED,
          variants: ['Q2/4', 'Q2/3'],
        },
        id: 'Q2/2',
        person: Interlocutors.GRANDMOTHER,
        _meta: {},
      },
      'Q2/3': {
        text: 'Что-то я не вижу, попробуй еще раз.',
        nextStep: 'Q2/1',
        id: 'Q2/3',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.message,
      },
      'Q2/4': {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q2/1',
        type: StepType.message,
        nextStep: 'Q3',
        text: 'Вижу-вижу 😊 Ох, ну какой же ты милый ребенок! 😍',
      },
      Q3: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3',
        person: Interlocutors.GRANDMOTHER,
        text: 'Вообще, я наслышана о твоей помощи другим и мне она тоже не помешает. Помоги мне, пожалуйста, выбраться отсюда.',
        callToAction: 'Справишься?',
        buttons: [
          {
            id: 'b1',
            text: 'Я помогу, не волнуйтесь',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q4',
        person: Interlocutors.GRANDMOTHER,
        text: 'Спасибо тебе 😊. Похоже, здесь на двери цифровой замок, чтобы открыть его нужно будет ответить на несколько вопросов.',
        buttons: [
          {
            id: 'b1',
            text: 'Открыть замок',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q5',
        person: Interlocutors.GRANDMOTHER,
        text: 'Тебе нужно выбрать не более двух вариантов ответа из предложенных.',
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: TEST_TVOI_VECTOR_1, // ТЕСТ: Твой вектор (тип мышления) 1
      Q7_0: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q7_0',
        person: Interlocutors.SYSTEM,
        text: 'Рядом с дверью ты видишь небольшой ломик. Вдруг пригодится?',
        callToAction: 'Взять ломик',
        buttons: [
          {
            id: 'b1',
            text: 'ПОЛУЧЕН ПРЕДМЕТ «Ломик»',
            nextStep: 'Q8',
          },
        ],
      },
      Q7: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        callToAction: '«Ломик»',
        text: '',
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с бабулей',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q8',
        person: Interlocutors.GRANDMOTHER,
        text: `Ура! С твоей помощью я освободилась! Пора бы мне возвращаться домой!
          Проводишь меня?
          `,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно!',
            nextStep: 'Q9',
          },
          {
            id: 'b2',
            text: 'Бабуля, нам нужно спасать остальных, я вернусь за тобой позже. (Игровое время не будет заморожено)',
            nextStep: 'Q9',
          },
          {
            id: 'b3',
            text: 'Бабуля, я пока пойду спасать остальных, а тебя проводит Вжух. (Игровое время будет заморожено на 5 минут)',
            nextStep: 'Q9',
          },
          {
            id: 'b4',
            text: 'Бабуля, я покажу тебе дорогу, давай посмотрим с маяка! (Игровое время будет заморожено на 15 минут)',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q9',
        person: Interlocutors.GRANDMOTHER,
        text: `Спасибо, дружок. Мы, бабушки, кое-что храним для внуков. Держи, тебе это пригодится.
        Он летает, когда это очень нужно!`,
        callToAction: 'ПОЛУЧЕН ПРЕДМЕТ «ковёр-самолет»',
        buttons: [
          {
            id: 'b1',
            text: 'Выйти на карту',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_8_6: Scenario = {
  scenarioId: SceneId.scenario_1_8_6,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors.SYSTEM.name,
    person,
    text: 'А, это снова ты. Все еще не готово. Приходи позже.',
    buttons: ['Закрыть'],
  },
};
