import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIsProfileAvailable } from '../hooks/useIsProfileAvailable';
import { useCallback } from 'react';
import { routes } from '../../common/shared/constants/routes';
import { Button } from '../../common/shared/ui/Button';
import { Box } from '../../common/shared/ui/Box';
import { Icon } from '../../common/shared/ui/Icon';

export const GameModalHooks = () => {
  const isProfileAvailable = useIsProfileAvailable();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    if (isProfileAvailable) {
      history.push(routes.profile);
      sessionStorage.removeItem('reloaded');

      // url меняется, но почему-то не открывается страница профиля, поэтому релоуд страницы
      window.location.reload();
    }
  }, [history, isProfileAvailable]);

  return (
    <Button $maxW='230px' onClick={handleLogout}>
      <Box $justify='space-between' $isWrap={false} $align='center'>
        Перейти в профиль
        <Icon $mL='12px' icon='ico_chevron' />
      </Box>
    </Button>
  );
};
