import { Interlocutors, StepType, TestStep } from '../../../../types/scenario';

const person = Interlocutors.TEACHER;

export const TEST_TVOI_VECTOR: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'Q6',
  name: 'Твой вектор 1/3',
  nextStep: 'Q6',
  order: [
    {
      description:
        'С помощью твоих ответов мы определим, какие направления будут главными для изучения в университете. Отвечай честно и выбирай только то, что тебе близко.',
      subtest: 'T1',
      question: 'Q39',
    },
    {
      description: 'Теперь давай выберем, что студенты будут узнавать на дополнительных занятиях.',
      subtest: 'T1',
      question: 'Q16',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q17',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q11',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q10',
    },
    {
      description: 'Ну и последний вопрос просто о тебе.',
      subtest: 'T1',
      question: 'Q21',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person,
  type: StepType.test,
};

export const TEST_TVOI_VIBOR_1: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'QTest',
  name: 'Твой выбор 1/5',
  nextStep: 'Q23',
  order: [
    {
      description: '',
      subtest: 'T1',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};
