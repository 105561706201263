import React from 'react';
import { Text } from '../Text';
import { HeaderModalListStyled } from './ModalAttachmentVideoStyled';

export const СonclusionText = () => {
  return (
    <HeaderModalListStyled>
      <Text $textStyle='p18' $mB='12px'>
        Важно!!!!
      </Text>
      <li>
        <p>
          Помни, что на загрузку файла может понадобиться больше 10 минут, это зависит от скорости
          твоего интернета. Можно уменьшить размер видео по ссылке{' '}
          <a href='https://video-converter.com/ru/' target='_blank' rel='noreferrer'>
            https://video-converter.com/ru/
          </a>{' '}
          или в другом конвертере до 10-20 Мбайт, что не повлияет на качество загружаемого
          видеофайла.
        </p>
      </li>
      <li>
        Загрузка файла не означает его отправку на проверку. Отправка происходит только после
        нажатия на кнопку «Отправить».
      </li>
      <li>
        Перед отправкой не забудь проверить, какой файл ты загрузил. После отправки видеовизитки ее
        нельзя будет заменить!
      </li>
      <li>
        Чтобы заменить неверно загруженный файл, воспользуйся кнопкой «Удалить». После удаления
        загрузи нужный файл.
      </li>
      <li>Видеовизитка, прикреплённая, но не отправленная, не сохраняется на платформе.</li>
      <li>
        Если тебе сложно справиться самостоятельно, попроси наставника или кого-то из родных помочь
        тебе с загрузкой видеовизитки.
      </li>
    </HeaderModalListStyled>
  );
};
