/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

export const TK_TEST_1: TestStep = {
  id: 'TK',
  person: Interlocutors.CAT,
  type: StepType.test,
  external_id: 'test-tvoi-kod-junior',
  path: 'test-tvoi-kod-junior-210401.json',
  name: 'Твой Код 1',
  order: [
    {
      description: `Сейчас будет четыре варианта. Хотя бы один из них нужно отметить «Точно да» и один «Определенно нет». А вот и первый вопрос:`,
      subtest: 'T1',
      question: 'Q1',
    },
    {
      description:
        'Отлично! Идем дальше. В этом вопросе, как и в прошлом, нужно выбрать четыре варианта ответа. Хотя бы один надо отметить «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q2',
    },
    {
      description:
        'Ничего сложного, правда? Продолжай отвечать так же: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет»',
      subtest: 'T1',
      question: 'Q3',
    },
    {
      description:
        'Очень приятно узнать тебя поближе. Следующий вопрос и все те же условия: из четырех вариантов минимум один «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q4',
    },
    {
      description:
        'О, абсолютно с тобой согласен! Я бы тоже выбрал такие же варианты. Продолжим с вопросами. Этот ничем не отличается от предыдущих: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q5',
    },
    {
      description:
        'Ты очень интересный человек! Здесь отвечай аналогично: из четырех минимум один вариант «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q6',
    },
    {
      description:
        'А я вот никогда не бывал на физкультуре, котам не очень-то это положено. Вопросов осталось совсем немного. Сейчас тоже выбирай хотя бы один «Точно да» и один «Определенно нет». Вариантов все так же четыре.',
      subtest: 'T1',
      question: 'Q7',
    },
  ],
  nextStep: 'Q9',
};

// const buttons: QuestionButton[] = [
//   {
//     id: 'b1',
//     text: 'Что тут все же произошло?',
//     nextStep: 'Q5',
//   },
//   {
//     id: 'b2',
//     text: 'Где мы сейчас находимся?',
//     nextStep: 'Q6/0',
//   },
//   {
//     id: 'b3',
//     text: 'Расскажи о себе!',
//     nextStep: 'Q7',
//   },
//   {
//     id: 'b4',
//     text: 'Что дальше?',
//     nextStep: 'Q8',
//   },
// ]; // c прошлого сценария, возможно пригодится

export const scenario_1_1: Scenario = {
  scenarioId: SceneId.scenario_1_1,
  type: ScenarioType.chat,
  data: {
    scene_id: 'scenario_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.question,
        id: 'Q1',
        person: Interlocutors.CAT,
        maxAnswers: 1,
        text: `Привет! Давно не виделись. Сколько же времени прошло?
 
        Помнишь меня?
        `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_2',
            text: 'Привет! Нет, давай знакомиться 😻',
          },
          {
            id: 'b2',
            nextStep: 'Q1_3',
            text: 'Привет, Вжух! Приятно снова тебя видеть',
          },
        ],
      },
      Q1_2: {
        type: StepType.question,
        id: 'Q1_2',
        person: Interlocutors.CAT,
        maxAnswers: 1,
        text: `Меня зовут Вжух! Я говорящий кот парка «Большая перемена». Сколько себя помню, гулял по этому парку с совсем малых лап 😻`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_3',
            text: 'Приятно познакомиться, Вжух!',
          },
        ],
      },
      Q1_3: {
        type: StepType.text_question,
        id: 'Q1_3',
        person: Interlocutors.CAT,
        text: `Вот твой входной билет в парк`,
        callToAction: 'Напиши на нём своё имя, и пойдём скорее!',
        nextStep: 'Q2',
        variable: 'NAME',
      },
      Q2: {
        id: 'Q2',
        person: Interlocutors.CAT,
        type: StepType.question,
        text: `Я так рад, что встретил тебя, {{NAME}}! 😻 Видишь ли, у нас тут происходит что-то необъяснимое. Раньше парк «Большая перемена» был ярким и жизнерадостным местом с самыми уникальными и прекрасными объектами России.
 
        Но в последнее время все изменилось. Достопримечательности парка, одна за другой, мистическим образом поменялись. Из-за этого жители парка теперь не могут заняться своими обычными делами. 🙀
         
        Никто не знает, что произошло, и все очень заняты восстановлением парка. А я думаю, необходимо провести расследование и понять, что произошло! Но один я не справлюсь, у меня же лапки 😿
        
         Нам нужно вместе разобраться во всём этом!`,
        callToAction: 'Поможешь мне?',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q3',
            text: 'Конечно!',
          },
          {
            id: 'b2',
            nextStep: 'Q3_1',
            text: 'Вот еще, звучит скучно',
          },
        ],
      },
      Q3: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3',
        person: Interlocutors.CAT,
        text: `Как хорошо, что ты со мной! Вдвоем не так страшно. Пока мы идем, может ты хочешь что-то узнать?`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q4_1',
            text: 'Для начала, расскажи, где это мы?',
          },
        ],
      },
      Q3_1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3_1',
        person: Interlocutors.CAT,
        text: `Давай ты посмотришь, какие чудеса у нас творятся. А пока идём, спрашивай, что захочешь!`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q4_1',
            text: 'Хорошо. А где мы находимся?',
          },
        ],
      },
      Q4_1: {
        type: StepType.message,
        id: 'Q4_1',
        person: Interlocutors.CAT,
        text: `Мы в «Большой перемене». Это уникальный парк, где собраны копии самых интересных и удивительных достопримечательностей России!`,
        nextStep: 'Q4_2',
      },
      Q4_2: {
        type: StepType.message,
        id: 'Q4_2',
        person: Interlocutors.CAT,
        text: `Здесь так здорово, что многие, приехав в парк однажды, остаются здесь жить и работать... По крайней мере, раньше так было… 😿`,
        nextStep: 'Q4_3',
      },
      Q4_3: {
        type: StepType.message,
        id: 'Q4_3',
        person: Interlocutors.CAT,
        text: `А сейчас здесь почти никого не осталось, некому присматривать за парком. Большинство достопримечательностей пришли в негодность. Где-то барахлит электричество, в каких-то точках пропала связь с внешним миром, а где-то даже люди стали сами не свои. Нужно обязательно привести все в порядок! Работы непочатый край! 🙀`,
        nextStep: 'Q4_4',
      },
      Q4_4: {
        type: StepType.message,
        id: 'Q4_4',
        person: Interlocutors.CAT,
        text: `Мы не теряем надежду и знаем, что с твоей помощью парк обретет былой цветущий вид! 😻`,
        nextStep: 'Q4_5',
      },
      ...splitStep({
        type: StepType.question,
        id: 'Q4_5',
        person: Interlocutors.CAT,
        text: `Если ты поможешь, мы в долгу не останемся. 😸`,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q5',
            text: 'Помогу! Но с чего мы начнём?',
          },
        ],
      }),
      Q5: {
        type: StepType.message,
        id: 'Q5',
        person: Interlocutors.CAT,
        text: `Как я и говорил раньше, в парке происходит мистика, а экспонаты пострадали. Я был в библиотеке — там все книги взлетели в воздух! Сам едва лапы унёс. Так вот, идём туда и всё выясним на месте!`,
        nextStep: 'Q6',
      },
      Q6: {
        type: StepType.message,
        id: 'Q6',
        person: Interlocutors.CAT,
        text: `Пока идем, расскажи о себе. Мне и другим жителям парка не терпится узнать про тебя побольше.`,
        nextStep: 'Q7',
      },
      Q7: {
        type: StepType.message,
        id: 'Q7',
        person: Interlocutors.CAT,
        text: `Я подготовил несколько вопросов и ответов к ним. Выбери, пожалуйста, насколько тот или иной вариант тебе подходит.`,
        nextStep: 'Q8',
      },
      Q8: {
        type: StepType.question,
        id: 'Q8',
        person: Interlocutors.CAT,
        text: `Отвечай «Точно да», если вариант про тебя, и «Определенно нет», если на тебя это не похоже. А на случай трудностей есть вариант «Не знаю». Не переживай, все просто 😺`,
        nextStep: 'TK',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'TK',
            text: 'Хорошо',
          },
        ],
      },
      TK: TK_TEST_1, // ТЕСТ: Твой код (тип личности) 1
      Q9: {
        type: StepType.message,
        id: 'Q9',
        person: Interlocutors.CAT,
        text: `Ты молодец! Я так рад, что именно ты нам помогаешь! Скоро о тебе узнают все в парке 😻`,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
